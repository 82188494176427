<template>
    <div>
        <div class="t">
          <div class="tool">
              <el-date-picker v-model="searchForm.month" type="month" placeholder="选择月" format="yyyy-MM" value-format="yyyy-MM-dd HH:mm:ss" class="a" @change='Search'></el-date-picker>
              <el-input v-model="searchForm.name" placeholder="请输入点位名称" class="a"></el-input>
              <el-button type="primary" plain @click="Search">查询</el-button>
          </div>
          <div class="tool" style="margin-right:30px">
            <div class="tool">
              <span class="label">售出</span>
              <div class="box" style="background-color: #A0CFFF;"></div>
            </div>
            <div class="tool">
              <span class="label">锁定</span>
              <div class="box" style="background-color: #FF8C78;"></div>
            </div>
            <div class="tool">
              <span class="label">预选</span>
              <div class="box" style="background-color: #3EA751;"></div>
            </div>
          </div>
        </div>

        <el-table :data='MediaDataList' :highlight-current-row='true' border size="mini" :cell-style="cellStyle" :span-method="SpanRow"  max-height='calc(100vh - 165px)' height='calc(100vh - 165px)'>
            <el-table-column align="center" prop="media_name" label="名称" width="150px" fixed></el-table-column>
            <el-table-column align="center" prop="media_community_addr" label="媒体位置" width="120px" fixed show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="media_addr" label="点位位置"></el-table-column>
            <el-table-column  :label="item.label" v-for="item in Days" :key="item.id" align="center" width="50" :prop="item.name"></el-table-column>
        </el-table>
        <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="total" background :page-size="searchForm.pageSize"></el-pagination>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      searchForm: {
        month: new Date(),
        name: '',
        pageNo: 1,
        pageSize: 20
      },
      Days: [],
      total: 0,
      MediaDataList: [],
    }
  },
  created () {
    this.searchForm.month = new Date()
    this.GetDays()
  },
  mounted () {
    this.GetDataList()
  },
  methods: {
    GetDays(){
      this.Days=[]
      // var t=new Date(this.searchForm.month)
      // var year =t.getFullYear()
      // var month = t.getMonth() + 1
      // var day = new Date(year, month, 0)
      // var days = day.getDate()
      for (var i = 1; i <= 31; i++) {
        this.Days.push({
          id: i,
          label: i + '号',
          name: 'order_name_' + i,
          state: 0
        })
      }
    },
    GetDataList () {
      this.GetDays()
      util.Get('plantime/getplantime', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          if (res.list.length > 0) {
            res.list.forEach(a => {
              for (var index = 1; index <= 31; index++) {
                a['order_name_' + index] = ''
              }
            })
            res.list.forEach(a => {
              if (a.listOrder.length > 0) {
                a.listOrder.forEach(b => {
                  var t = new Date(b.order_begin_time)
                  var day = t.getDate()
                  var year = t.getFullYear()
                  var month = t.getMonth() + 1

                  var t1 = new Date(this.searchForm.month)
                  var year1 = t1.getFullYear()
                  var month1 = t1.getMonth() + 1
                  if (year < year1 || month < month1) {
                    day = 1
                  }

                  a['order_name_' + day] = b.order_name
                })
              }
            })
          }
          this.MediaDataList = res.list
          this.total = res.total
        }
      })
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },
    // 设置单元格颜色
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (row.listOrder.length > 0) {
        for (var index = 0; index < row.listOrder.length; index++) {
          if (row.listOrder[index].order_type !== null) {

            var t = new Date(this.searchForm.month)
            var year = t.getFullYear()
            var month = t.getMonth() + 1
            var day=t.getDate()

            var t1 = new Date(row.listOrder[index].order_begin_time)
            let y = t1.getFullYear()
            let m = t1.getMonth() + 1
            let d = t1.getDate()

            var beginNum = 1// 起始日期
            if (y === year && month === m) {
              beginNum = d
            }

            var t2 = new Date(year, month, 0)
            var endNum = 1// 结束日期
            

            var sty = 'background:#A0CFFF'
            if (row.listOrder[index].order_type === 0) {
              t2 = new Date(row.listOrder[index].order_end_time)
            } else if (row.listOrder[index].order_type === 1) {
              t2 = new Date(row.listOrder[index].order_lock_time)
              sty = 'background:#FF8C78'
            }
            else {
              t2 = new Date(row.listOrder[index].order_end_time)
              sty = 'background:#3EA751'
            }
            y = t2.getFullYear()
            m = t2.getMonth() + 1
            d = t2.getDate()

            if (y === year && month === m) {
              endNum = d
             
            }
            if (columnIndex >= (beginNum + 2) && columnIndex <= (endNum + 2)) {
              return sty
            }
          }
        }
      }
    },
    // 合并行
    SpanRow ({ row, column, rowIndex, columnIndex }) {

      if (row.listOrder.length > 0) {
        for (var index = 0; index < row.listOrder.length; index++) {
          if (row.listOrder[index].order_type !== null) {
            var t = new Date(this.searchForm.month)
            var year = t.getFullYear()
            var month = t.getMonth() + 1

            var t1 = new Date(row.listOrder[index].order_begin_time)
            let y = t1.getFullYear()
            let m = t1.getMonth() + 1
            let d = t1.getDate()

            var beginNum = 1// 起始日期
            if (y === year && month === m) {
              beginNum = d
            }

            var t2 = new Date(year, month, 0)
            var endNum = 1// 结束日期

            if (row.listOrder[index].order_type === 1) {
              t2 = new Date(row.listOrder[index].order_lock_time)
             
            } else {
              t2 = new Date(row.listOrder[index].order_end_time)
            }


            y = t2.getFullYear()
            m = t2.getMonth() + 1
            d = t2.getDate()


            if (y === year && month === m) {
              endNum = d
            }
           
            if (columnIndex === (beginNum + 2)) {
              return [1, endNum - beginNum + 1]
            }
            if (columnIndex > (beginNum + 2) && columnIndex < (endNum + 3)) {
              return [0, 0]
            }
          }
        }
      }
    },
    Search () {
      this.GetDataList()
    }
  }
}
</script>

<style scoped>
.tool{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.label{
  font-size: 9px;
  margin-right: 5px;
  margin-left: 12px;
}
.t{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.a{
    width:200px;
    margin-right: 15px;
}
.el-table{
    margin-top: 15px;
}
.box{
  width: 15px;
  height: 15px;

}
</style>
